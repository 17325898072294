import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { ReactElement } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import FeatureCard from '../components/common/featureCard';
import { cardData } from '../utils/entities';
import { Image } from '../utils/image';

const StyledButton = styled(Button)`
    border-radius: 100rem;
    color: #29558c;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to top, #9ba4d9, #94dfe7);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff),
        linear-gradient(to top, #9ba4d9, #94dfe7);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: solid 1px transparent;
    box-shadow: 2px 1000px 1px #fff inset;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &:hover {
        color: black;
    }
`;

const cardDetails: cardData[] = [
    {
        title: `B2B Integration`,
        description: `StackIAM allows you to integrate with other businesses, enterprise directory and give your business partners access to information they might need.`,
        imgUrl: `/images/b-2-b.svg`,
        slug: `/b2b`,
    },
    {
        title: `Access Gateway`,
        description: `StackIAM allows you to integrate with other businesses, enterprise directory and give your business partners access to information they might need.`,
        imgUrl: `/images/login.svg`,
        slug: `/access-gateway`,
    },
    {
        title: `Customer Dashboard`,
        description: `Customers can view their dashboard capturing who they are, settings, who has access and what kind of information they have access to.`,
        imgUrl: `/images/dashboard.svg`,
        slug: `/customer-dashboard`,
    },
    {
        title: `Secure authentication with SSO`,
        description: `Single sign on with SAML connects helps customers login into multiple applications with a single username and password.`,
        imgUrl: `/images/keyword.svg`,
        slug: `/secure-sso`,
    },
    {
        title: `Adaptive Multi factor Authentication`,
        description: `WorkForce / Employee Identity management - Useful Unified view to view all employees and assign them into different groups based on different departments and permission to different resources. `,
        imgUrl: `/images/multi.svg`,
        slug: `/multifactor`,
    },
    {
        title: `Data verification & Management`,
        description: `Verify and authenticate all your information, manage access, secure and customize user login flow`,
        imgUrl: `/images/correct.svg`,
        slug: `/data-verification`,
    },
    {
        title: `Easy Application`,
        description: `Apply easily to any company or any other form of application with your already aggregated verified information, using your unique and  secured login credentials without having to fill any more forms ever again.`,
        imgUrl: `/images/easy-application.svg`,
        slug: `/easy-application`,
    },
    {
        title: `Business Service`,
        description: `Verify and authenticate all your information, manage access, secure and customize user login flow`,
        imgUrl: `/images/business-service.svg`,
        slug: `/business-service`,
    },
];

const FeaturesPackages = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="StackIAM Features Packages"
                desc="StackIAM is a trusted identity management cloud service that provides secured and seamless interactions between individuals and organizations. With the StackIAM platform, users can connect to all their applications and manage activities from their devices"
                imageUrl="/images/features-packages-hero@3x.png"
            />
            <Row noGutters>
                <Col
                    xs="12"
                    style={{
                        textAlign: `center`,
                        paddingTop: `3rem`,
                        color: `#2e598f`,
                        paddingBottom: `3rem`,
                    }}
                >
                    <div
                        style={{
                            fontSize: `2rem`,
                            paddingBottom: `1rem`,
                        }}
                    >
                        Features
                    </div>
                </Col>
            </Row>
            <Row
                noGutters
                style={{
                    // backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
                    paddingLeft: `3rem`,
                    paddingRight: `3rem`,
                    paddingBottom: `3rem`,
                }}
            >
                <Col md="12" lg="4">
                    <Card
                        style={{
                            backgroundColor: `#36a9f5`,
                            color: `#ffffff`,
                            margin: `1rem`,
                        }}
                    >
                        <Card.Body
                            style={{ textAlign: `center`, height: `30rem` }}
                        >
                            <Image
                                style={{
                                    height: `5rem`,
                                    width: `5rem`,
                                    marginTop: `2rem`,
                                    marginBottom: `2rem`,
                                }}
                                src="/images/process.svg"
                            />
                            <div
                                style={{
                                    fontSize: `1.05rem`,
                                    marginBottom: `1rem`,
                                    fontWeight: `bold`,
                                }}
                            >
                                <Link
                                    style={{
                                        color: `#ffffff`,
                                        textDecoration: `none`,
                                    }}
                                    to="/lifecycle"
                                >
                                    Life cycle management
                                </Link>
                            </div>
                            <p style={{ height: `10rem` }}>
                                Use StackIAM to coordinate and restrict the
                                identification, access and governance of
                                identities for access to your business tools and
                                information.
                            </p>
                            <div>
                                <StyledButton>Learn more</StyledButton>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {cardDetails.map(card => (
                    <FeatureCard
                        key={card.title}
                        imgUrl={card.imgUrl}
                        description={card.description}
                        title={card.title}
                        slug={card.slug}
                        packages={true}
                    />
                ))}
            </Row>
            <BannerSection />
        </Container>
    </Layout>
);

export default FeaturesPackages;
